@import "_variables";

.img-flex {
  .col3 {
    margin-top: 20px;
    @include sp {
      margin-top: 10px;
    }
  }
}

.flex-col {
  &-bw {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    @include sp {
      margin-bottom: 1em;
      display: block;
    }
  }
}

.project-about {
  &__img {
    margin: 50px auto;
    text-align: center;
    @include tb {
      margin: calcTb(50) auto;
      width: calcTb(400);
    }
    @include sp {
      margin: calcSp(50) auto;
      width: 63%;
    }
  }
  &__text {
    margin-bottom: 30px !important;
    font-size: 18px;
    text-align: center;
    line-height: 2;
    @include tb {
      margin-bottom: calcTb(30) !important;
      font-size: calcTb(18);
    }
    @include sp {
      font-size: 14px;
      text-align: left;
      br {
        display: none;
      }
    }
  }
}

.project-design {
  &__img {
    margin: 50px auto;
    text-align: center;
    @include tb {
      margin: calcTb(50) auto;
    }
    @include sp {
      margin: calcSp(50) auto;
    }
    img {
      width: 400px;
      @include tb {
        width: calcTb(400);
      }
      @include sp {
        width: 63%;
      }
    }
  }
}

//推進委員会委員
.project-member {
  &__list {
    li {
      position: relative;
      min-height: 140px;
      padding-left: 125px;
      margin-bottom: 40px;
      font-size: 14px;
      @include tb {
        min-height: calcTb(140);
        padding-left: calcTb(125);
        margin-bottom: calcTb(40);
        font-size: calcTb(14);
      }
      @include sp {
        min-height: 100px;
        padding-left: 30%;
        margin-bottom: 30px;
        font-size: 12px;
      }
      figure {
        margin-top: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100px;
        @include tb {
          width: calcTb(100);
        }
        @include sp {
          width: 25%;
        }
        img {
          max-width: 100%;
          vertical-align: bottom;
        }
      }
      .name {
        margin-bottom: 13px;
        font-size: 20px;
        font-weight: 600;
        line-height: 1;
        @include tb {
          margin-bottom: calcTb(13);
          font-size: calcTb(20);
        }
        @include sp {
          margin-bottom: 0.75em;
          font-size: 16px;
        }
        span {
          padding-left: 0.5em;
          margin-left: 5px;
          font-size: 14px;
          font-weight: 300;
          @include tb {
            margin-left: calcTb(5);
            font-size: calcTb(14);
          }
          @include sp {
            padding-left: 0;
            margin-left: 0;
            margin-top: 0.3em;
            line-height: 1.3;
            display: block;
            font-size: 11px;
          }
        }
      }
      a {
        color: #ff7800;
      }
    }
  }
}

.project-activity,
.project-channel,
.project-collaboration {
  h2 {
    span {
      display: block;
      font-size: 80%;
    }
  }
  article {
    margin-bottom: 100px;
    @include tb {
      margin-bottom: calcTb(100);
    }
    @include sp {
      margin-bottom: calcSp(100);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    a {
      color: #ff7800;
      text-decoration: underline;
    }
  }

  &-head {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 600;
    @include tb {
      margin-bottom: calcTb(40);
      font-size: calcTb(18);
    }
    @include sp {
      margin-bottom: calcSp(40);
      font-size: 14px;
    }
  }
  &-nav {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    border-top: 1px solid #fff;
    border-left: 1px solid #fff;
    @include tb {
      margin-bottom: calcTb(20);
    }
    li {
      width: 16.666%;
      @include sp {
        width: 50%;
        font-size: 14px;
      }
      .year-2024 & {
        &:nth-child(1) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2023 & {
        &:nth-child(2) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2022 & {
        &:nth-child(3) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2021 & {
        &:nth-child(4) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2020 & {
        &:nth-child(5) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2019 & {
        &:nth-child(6) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2018 & {
        &:nth-child(7) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2017 & {
        &:nth-child(8) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2016 & {
        &:nth-child(9) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2015 & {
        &:nth-child(10) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2014 & {
        &:nth-child(11) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2013 & {
        &:nth-child(12) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2012 & {
        &:nth-child(13) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      .year-2011 & {
        &:nth-child(14) {
          a {
            pointer-events: none;
            background: #ff7800;
            color: #fff;
          }
        }
      }
      a,
      span {
        display: block;
        box-sizing: border-box;
        background: #f2f0f0;
        text-align: center;
        line-height: 40px;
        border-right: 1px solid #fff;
        border-bottom: 1px solid #fff;
        color: #ff7800;
        font-weight: 500;
        @include sp {
          line-height: 35px;
        }
        &:hover {
          background: #ff7800;
          color: #fff;
          opacity: 1;
        }
      }
    }
  }
  &-anker {
    margin-bottom: 50px;
    border-top: 1px solid #ff7800;
    @include tb {
      margin-bottom: calcTb(50);
    }
    li {
      display: grid;
      grid-template-columns: 100px 1fr;
      align-items: stretch;
      border-right: 1px solid #ff7800;
      font-size: 14px;
      @include tb {
        grid-template-columns: calcTb(100) 1fr;
        font-size: calcTb(14);
      }
      @include sp {
        font-size: 12px;
        line-height: 1.5;
      }
      &:last-child {
        span {
          border-bottom: 1px solid #ff7800;
        }
      }
      span {
        display: block;
        height: 100%;
        padding: 5px 10px;
        background: #ff7800;
        color: #fff;
        box-sizing: border-box;
        text-align: center;
        word-break: break-all;
        border-bottom: 1px solid #fff;
        @include tb {
          padding: calcTb(5) calcTb(10);
        }
      }
      a {
        padding: 5px 10px;
        color: #ff7800;
        font-weight: 500;
        border-bottom: 1px solid #ff7800;
        border-left: 1px solid #fff;
        @include tb {
          padding: calcTb(5) calcTb(10);
        }
        &:hover {
          color: #fff;
          background: #ff7800;
          opacity: 1;
          border-left: none;
        }
      }
    }
  }
  &__text {
    margin-bottom: 45px !important;
    @include tb {
      margin-bottom: calcTb(45) !important;
    }
    @include sp {
      margin-bottom: calcSp(45) !important;
    }
  }
  &__info {
    margin-bottom: 2em;
    position: relative;
    dt {
      position: absolute;
      width: 6em;
      text-align: justify;
      text-align-last: justify;
      font-weight: bold;
      @include sp {
        position: static;
        width: 100%;
        text-align: left;
        text-align-last: auto;
        font-size: 14px;
      }
    }
    dd {
      margin-bottom: 1em;
      padding-left: 8em;
      @include sp {
        padding-left: 0;
      }
    }
  }
  &__img {
    margin-top: 50px;
    width: 100%;
    @include tb {
      margin-top: calcTb(50);
    }
  }
  &__video {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 56.25%;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  &__schedule {
    width: 100%;
    border: solid 1px #c0c0c0;
    margin-top: 20px;
    margin-bottom: 40px;
    @include tb {
      margin-top: calcTb(20);
      margin-bottom: calcTb(40);
    }
    &-item {
      height: 35px;
      background: #33a4ac;
      color: #fff;
      font-size: 16px;
      @include tb {
        height: calcTb(35);
      }
    }
    th {
      border-right: solid 1px #fff;
      font-weight: normal;
      &:last-child {
        border: solid 1px #c0c0c0;
        font-weight: normal;
      }
    }
    tr {
      &:nth-child(even) {
        background: #def3f5;
      }
    }
    td {
      padding: 5px 10px;
      border-right: solid 1px #c0c0c0;
      font-size: 13px;
      @include tb {
        padding: calcTb(5) calcTb(10);
        font-size: calcTb(13);
      }
      a {
        display: block;
        text-align: center;
        color: #ff7800;
        text-decoration: underline;
      }
    }
  }
  &-presenter {
    margin-top: 30px;
    display: flex;
    align-items: start;
    img {
      margin-top: 0.5em;
      margin-right: 30px;
      width: 25%;
    }
  }
}
.project-channel {
  &__item {
    margin: 30px auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include tb {
      margin-top: calcTb(30);
    }
    @include sp {
      margin-top: 20px;
    }
    .project-activity__video {
      margin-bottom: 1em;
    }    
    .project-collaboration__list {
      margin-bottom: 0;
      li {
        margin-top: 0;
      }
    }
  }
  &-anker {
    li {
      display: block;
      a {
        display: block;
        border-left: 1px solid;
      }
    }
  }
}

.project-song {
  &__btn {
    text-align: center;
    @include sp {
      margin-top: 1em;
      display: flex;
      justify-content: center;
    }
    li {
      display: inline-block;
      margin: 0 20px;
      @include tb {
        margin: 0 calcTb(20);
      }
      @include sp {
        margin: 0 1%;
        width: 42%;
      }
    }
  }
  &__download {
    position: relative;
    margin: 60px 0;
    padding: 30px;
    background: #f2f0f0;
    border-radius: 10px;
    @include tb {
      margin: calcTb(60) 0;
      padding: calcTb(30);
    }
    @include sp {
      margin: calcSp(60) 0;
      padding: 20px;
    }
  }
  &__concept {
    &-profile {
      margin-top: 30px;
      display: flex;
      font-size: 14px;
      @include tb {
        margin-top: calcTb(30);
        font-size: calcTb(14);
      }
      @include sp {
        display: block;
      }
      dt {
        width: 15em;
        font-weight: 600;
      }
    }
  }
  &__lyric {
    p {
      margin-bottom: 2em;
      line-height: 2;
    }
    .lyric-icon {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        width: 160px;
        height: 160px;
        background: url(../images/project/song/family.png) center / 100% no-repeat;
        @include tb {
          width: calcTb(160);
          height: calcTb(160);
        }
        @include sp {
          position: static;
          margin: calcSp(50) auto 0;
          display: block;
        }
      }
    }
  }
}

.project-channel,
.project-collaboration {
  h2 {
    margin-bottom: 0;
    &.img-title {
      padding-left: 0;
      margin-left: 0;
      margin-bottom: 40px;
      border-left: none;
      @include tb {
        margin-bottom: calcTb(40);
      }
      @include sp {
        margin-bottom: calcSp(40);
      }
      &::before {
        display: none;
      }
      img {
        margin: auto;
        display: block;
      }
    }
  }
  h3 {
    margin: 20px auto 0;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0.05em;
    font-feature-settings: "palt";
    @include tb {
      font-size: calcTb(16);
    }
    @include sp {
      font-size: 14px;
    }
  }
  &__list {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include tb {
      margin-bottom: calcTb(30);
    }
    &::after {
      content: "";
      width: 220px;
      display: block;
      clear: both;
      @include tb {
        width: calcTb(220);
      }
    }
    li {
      margin-top: 30px;
      width: 220px;
      font-size: 12px;
      @include tb {
        margin-top: calcTb(30);
        width: calcTb(220);
        font-size: calcTb(12);
      }
      @include sp {
        margin-top: 20px;
        width: calc(50% - 10px);
      }
      img {
        margin-bottom: 0.5em;
        width: 100%;
      }
      a {
        color: #ff7800;
        text-decoration: underline;
      }
    }
  }
  &__movie {
    margin-bottom: 0.5em;
    position: relative;
    width: 100%;
    height: 391px;
    overflow: hidden;
    @include tb {
      height: calcTb(391);
    }
    @include sp {
      height: calcSp(560);
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      object-fit: cover;
      z-index: 1;
      visibility: visible;
      cursor: pointer;
      transition:
        opacity 0.3s,
        visibility 0.3s;
      &.js-play {
        opacity: 0;
        visibility: hidden;
      }
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
.project-collaboration {
  &__list {
    li {
      img {
        border: none;
      }
    }
  }
}

@import "./project/_archive";
