.project-activity--archive {
  /* ++++++++++++++++++++++++++++++++++++++++

	Global CSS

++++++++++++++++++++++++++++++++++++++++ */
  /* ----------------------------------------
	Common Settings
---------------------------------------- */

  #container {
    overflow: hidden;
    min-width: 970px;
  }

  .pcHide {
    display: none;
  }

  .pcBreak {
    display: block;
  }
  #fb-root {
    position: fixed;
    bottom: 100%;
  }

  /* ----------------------------------------
	header
---------------------------------------- */
  header {
    position: relative;
    z-index: 2;
    height: 205px;
    margin-bottom: 60px;
  }
  header > div {
    position: relative;
    width: 970px;
    height: 90px;
    margin: auto;
    padding: 14px 0;
  }
  header a,
  nav a,
  .subNav a {
    color: #313131;
    text-decoration: none !important;
  }

  /* subNav
---------------------------------------- */
  .subNav {
    border-right: none;
  }
  .subNav li {
    float: left;
    border-left: solid 1px #313131;
  }

  .subNav a {
    display: block;
    padding: 5px 0;
    font-size: 13px;
    line-height: 1;
  }

  header .subNav {
    position: absolute;
    right: 0;
    top: 18px;
  }

  .subNav li {
    border-left: 0;
    height: 20px;
    overflow: hidden;
    margin-right: 10px;
  }
  .subNav li {
    height: auto;
    margin-top: 10px;
    padding: 0 20px;
    border-right: solid 1px #000;
    overflow: hidden;
    margin-right: 10px;
    line-height: 1;
  }
  .subNav li.mail {
    margin-top: 0;
    border-right: 0;
  }
  header .subNav #mailmaga {
    width: 220px;
  }
  .subNav li.search {
    padding: 0;
    border-right: 0;
    margin-top: 0;
  }

  /* headerSearch
---------------------------------------- */
  #headerSearch {
    overflow: hidden;
    position: relative;
    right: 0;
    top: 0;
    margin-top: 10px;
    width: 265px;
    border-radius: 30px;
    background: #f2f2f2;
  }

  #headerSearch:before {
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
    width: 38px;
    height: 100%;
    border-radius: 0 30px 30px 0;
    background: #95a4a9;
  }

  #headerSearch .gsc-control-cse {
    padding: 0;
    border: 0;
    background: none;
  }

  #headerSearch .gsc-search-box {
    position: relative;
    z-index: 1;
    margin: 0;
  }

  #headerSearch .gsc-search-box-tools .gsc-search-box .gsc-input {
    padding: 0;
    line-height: normal;
  }

  #headerSearch .gsc-input-box {
    height: 27px;
    border: 0;
    background: none;
  }

  #headerSearch .gsib_a {
    padding: 4px 5px 0 14px;
  }

  #headerSearch .cse input.gsc-search-button,
  #headerSearch input.gsc-search-button {
    display: block;
    margin: 0;
    padding: 7px 15px 7px 10px;
    border: 0;
  }

  #headerSearch input.gsc-search-button,
  #headerSearch input.gsc-search-button:hover,
  #headerSearch input.gsc-search-button:focus {
    background: none;
  }

  /* nav
---------------------------------------- */
  #navBtn {
    display: none;
  }

  nav {
    padding-top: 4px;
    box-sizing: border-box;
    border-top: solid 1px #fbfbfb;
    border-bottom: solid 2px;
    border-bottom-color: #fff;
    box-sizing: border-box;
  }

  nav > ul {
    position: relative;
    width: 100%;
    height: 80px;
    margin: auto;
  }

  nav > ul > li {
    position: absolute;
    float: none;
    left: 0;
    top: 80px;
    width: 100%;
    height: 1px;
    padding-bottom: 20px;
    margin-right: 20px;
  }
  nav > ul > li > a {
    position: absolute;
    left: 50%;
    top: 0;
    height: 65px;
    margin-top: -75px;
    padding-bottom: 20px;
    background: url("/img/common/nav_project.png") no-repeat;
  }
  nav li.project > a {
    width: 121px;
    margin-left: -485px;
    margin-left: -520px;
    background: url("/img/common/nav_project.png") no-repeat;
  }
  /* nav li.employee>a {
	width: 121px;
	margin-left: -340px;
	background: url("/img/common/nav_employee.png") no-repeat;
} */
  /* 2023/07/21 added start */
  nav li.employee > a {
    width: 121px;
    margin-left: -370px;
    margin-left: -400px;
    background: url("/img/common/nav_employee.png") no-repeat;
  }
  /* 2023/07/21 added end */
  /* nav li.company>a {
	width: 140px;
	margin-left: -187px;
	background: url("/img/common/nav_company.png") no-repeat;
} */
  /* 2023/07/21 added start */
  nav li.company > a {
    width: 140px;
    margin-left: -252px;
    margin-left: -280px;
    background: url("/img/common/nav_company.png") no-repeat;
  }
  /* 2023/09/11 added start */
  nav li.supervisor > a {
    width: 190px;
    margin-left: -108px;
    margin-left: -140px;
    background: url("/img/common/nav_ikuboss.png") no-repeat;
  }
  /* 2023/09/11 added end */
  /* 2023/07/21 added end */
  /* nav li.library>a {
	width: 164px;
	margin-left: -18px;
	background: url("/img/common/nav_library.png") no-repeat;
} */
  /* 2023/07/21 added start */
  nav li.library > a {
    width: 164px;
    margin-left: -108px;
    margin-left: 50px;
    background: url("/img/common/nav_library.png") no-repeat;
  }
  /* 2023/07/21 added end */
  /* 2023/07/21 added start */
  nav li.seminar > a {
    width: 140px;
    margin-left: 55px;
    margin-left: 220px;
    background: url("/img/common/nav_seminar.png") no-repeat;
  }
  /* 2023/07/21 added end */
  /* nav li.event>a {
	width: 160px;
	margin-left: 175px;
	background: url("/img/common/nav_event.png") no-repeat;
} */
  /* 2023/07/21 added start */
  nav li.event > a {
    width: 160px;
    margin-left: 195px;
    margin-left: 360px;
    background: url("/img/common/nav_event.png") no-repeat;
  }
  /* 2023/07/21 added end */
  /* nav li.twitter>a {
	width: 120px;
	margin-left: 362px;
	background: url("/img/common/nav_twitter.png") no-repeat;
} */
  nav li > a:after {
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-position: top left;
    background-repeat: no-repeat;
    background-size: auto;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
  }
  nav li > a.active:after {
    visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
  nav li.project > a:after {
    background-image: url("/img/common/nav_project_on.png");
  }
  nav li.employee > a:after {
    background-image: url("/img/common/nav_employee_on.png");
  }
  nav li.company > a:after {
    background-image: url("/img/common/nav_company_on.png");
  }
  /* 2023/09/11 added start */
  nav li.supervisor > a:after {
    background-image: url("/img/common/nav_ikuboss_on.png");
  }
  /* 2023/09/11 added end */
  nav li.library > a:after {
    background-image: url("/img/common/nav_library_on.png");
  }
  /* 2023/07/21 added start */
  nav li.seminar > a:after {
    background-image: url("/img/common/nav_seminar_on.png");
  }
  /* 2023/07/21 added end */
  nav li.event > a:after {
    background-image: url("/img/common/nav_event_on.png");
  }
  /* nav li.twitter>a:after {
	background-image: url("/img/common/nav_twitter_on.png");
} */
  nav > ul > li > div:before {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 100%;
    border-bottom: 0;
    width: 18px;
    height: 17px;
    margin: 0 auto 0;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: auto;
    background-color: #fff;
    visibility: hidden;
    opacity: 0;
  }
  nav > ul > li.active > div:before {
    opacity: 1;
    visibility: visible;
  }
  nav li.project.active > div:before {
    margin-left: -430px;
    margin-left: -465px;
    background-image: url(/img/common/icon_nav_project.png);
  }
  /* nav li.employee.active>div:before {
	margin-left: -265px;
	background-image: url(/img/common/icon_nav_employee.png);
} */
  /* 2023/07/21 added start */
  nav li.employee.active > div:before {
    margin-left: -300px;
    margin-left: -330px;
    background-image: url(/img/common/icon_nav_employee.png);
  }
  /* 2023/07/21 added end */
  /* nav li.company.active>div:before {
	margin-left: -115px;
	background-image: url(/img/common/icon_nav_company.png);
} */
  /* 2023/07/21 added start */
  nav li.company.active > div:before {
    margin-left: -180px;
    margin-left: -210px;
    background-image: url(/img/common/icon_nav_company.png);
  }
  /* 2023/07/21 added end */
  /* 2023/09/11 added start */
  nav li.supervisor.active > div:before {
    margin-left: -180px;
    margin-left: -70px;
    background-image: url(/img/common/icon_nav_ikuboss.png);
  }
  /* 2023/09/11 added end */
  /* nav li.library.active>div:before {
	margin-left: 40px;
	background-image: url(/img/common/icon_nav_library.png);
} */
  /* 2023/07/21 added start */
  nav li.library.active > div:before {
    margin-left: -45px;
    margin-left: 110px;
    background-image: url(/img/common/icon_nav_library.png);
  }
  /* 2023/07/21 added end */
  /* 2023/07/21 added start */
  nav li.seminar.active > div:before {
    content: none;
    display: none;
  }
  /* 2023/07/21 added end */
  nav li.event.active > div:before {
    content: none;
    display: none;
  }
  /* nav li.twitter.active>div:before {
	content:none;
	display: none;
} */
  nav li a {
    display: block;
    position: relative;
    height: 100%;
    text-indent: -20000px;
  }

  nav li.active a:after {
    opacity: 1;
    visibility: visible;
  }

  nav .photo,
  nav .sub,
  nav .close,
  #navClose {
    display: none;
  }

  nav li li {
    position: relative;
    float: none;
    height: auto;
    margin: 0;
    display: inline-block;
  }
  nav li li:before {
    position: absolute;
    left: 0;
    top: 0;
    content: "・";
    display: inline-block;
  }
  nav > ul > li.active > div {
    opacity: 1;
    width: 100%;
    border-width: 1px;
    border-top: solid 2px;
    height: 140px;
    box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
    visibility: visible;
  }
  nav > ul > li.project.active > div {
    border-color: #ff7800;
  }
  nav > ul > li.employee.active > div {
    border-color: #ff7800;
    height: 110px;
  }
  nav > ul > li.company.active > div {
    border-color: #4b7ee8;
    height: 160px;
  }
  /* 2023/09/11 added start */
  nav > ul > li.supervisor.active > div {
    border-color: #4f71be;
    height: 160px;
  }
  /* 2023/09/11 added end */
  nav > ul > li.library.active > div {
    border-color: #72ad00;
  }
  nav > ul > li > div {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 100%;
    margin: 0px auto 0;
    border-top: solid 2px #ddd;
    transition: 0.2s;
    background: rgba(255, 255, 255, 0.9);
    visibility: hidden;
  }

  nav > ul > li > div > ul {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 120px;
    padding: 15px 0 0;
  }
  nav > ul > li > div > ul {
    position: absolute;
    left: 50%;
  }
  nav > ul > li.project > div > ul {
    width: 450px;
    margin-left: -485px;
  }
  /* nav>ul>li.employee>div>ul {
	width: 500px;
	height: 90px;
	margin-left: -340px;
} */
  /* 2023/07/21 added start */
  nav > ul > li.employee > div > ul {
    width: 500px;
    height: 90px;
    margin-left: -355px;
  }
  /* 2023/07/21 added end */
  /* nav>ul>li.company>div>ul {
	width: 580px;
	height: 130px;
	margin-left: -190px;
} */
  /* 2023/07/21 added start */
  nav > ul > li.company > div > ul {
    width: 580px;
    height: 130px;
    margin-left: -235px;
  }
  /* 2023/07/21 added end */
  /* 2023/09/11 added start */
  nav > ul > li.supervisor > div > ul {
    width: 580px;
    height: 130px;
    margin-left: -235px;
  }
  /* 2023/09/11 added end */
  /* nav>ul>li.library>div>ul {
	width: 450px;
	margin-left: -10px;
} */
  /* 2023/07/21 added start */
  nav > ul > li.library > div > ul {
    width: 450px;
    margin-left: -100px;
  }
  /* 2023/07/21 added end */
  nav li li a {
    display: inline-block;
    padding: 0 0 0 1em;
    border-bottom: solid 2px transparent;
    text-indent: 0;
    box-sizing: border-box;
    background: none;
  }

  nav li li a:after {
    display: none;
  }

  nav li li a:hover {
    opacity: 1;
    color: #000;
    border-bottom: #000;
  }

  nav .project li a:hover {
    border-bottom: solid 2px #ff7800;
  }

  nav .employee li a:hover {
    border-bottom: solid 2px #ff7800;
  }

  nav .company li a:hover {
    border-bottom: solid 2px #4b7ee8;
  }

  /* 2023/09/11 added start */
  nav .supervisor li a:hover {
    border-bottom: solid 2px #4f71be;
  }
  /* 2023/09/11 added end */

  nav .library li a:hover {
    border-bottom: solid 2px #72ad00;
  }

  /* 2023/07/21 added start */
  nav .seminar li a:hover {
    border-bottom: solid 2px #67b4ba;
  }
  /* 2023/07/21 added end */

  nav .event li a:hover {
    border-bottom: solid 2px #f682a6;
  }

  /* ----------------------------------------
	contents
---------------------------------------- */
  #contents {
    position: relative;
    z-index: 1;
    width: 970px;
    margin: auto;
    padding-bottom: 120px;
    @include tb {
      width: calcTb(970);
    }
    @include sp {
      width: 100%;
    }
  }

  article {
    float: right;
    width: 720px;
    @include tb {
      width: calcTb(720);
    }
    @include sp {
      width: 100%;
    }
  }

  h3,
  h3,
  h4 {
    font-weight: normal;
  }

  h2 img {
    display: block;
  }

  h3 {
    position: relative;
    margin-bottom: 20px;
    padding-left: 6px;
    border-left: solid 12px;
    letter-spacing: -1px;
    font-size: 24px;
    line-height: 1.2;
  }

  h3:before {
    content: "";
    position: absolute;
    left: -8px;
    top: 0;
    width: 4px;
    height: 100%;
    background: #fff;
  }

  h3.subtitle {
    margin-bottom: 20px;
    padding-left: 7px;
    border-left: solid 7px #ff7800;
    color: #313131;
    font-size: 18px;
    font-weight: 600;
    @include sp {
      margin-bottom: 10px;
      padding-left: 4px;
      border-left-width: 4px;
      font-size: 14px;
    }
  }

  h4 {
    margin-bottom: 5px;
    font-size: 20px;
    line-height: 1.5;
    @include sp {
      font-size: 14px;
    }
  }

  h4.subtitle {
    margin-bottom: 20px;
    padding-left: 7px;
    border-left: solid 7px;
    color: #313131;
    font-size: 18px;
  }

  section {
    margin-bottom: 80px;
    @include sp {
      margin: 0 auto 30px;
    }
  }

  article p {
    margin-bottom: 25px;
  }

  p + section {
    margin-top: 40px;
  }

  section section {
    margin-bottom: 40px;
  }

  section:last-child,
  article p:last-child {
    margin-bottom: 0;
  }

  #heading {
    margin-bottom: 60px;
    font-size: 20px;
    line-height: 1.5;
  }

  .coloredBox {
    padding: 30px;
    border-radius: 8px;
  }

  .red_bold {
    color: #ff0000;
    font-weight: bold;
  }

  .note {
    font-size: 12px;
  }

  p + .note {
    margin-top: -10px;
  }

  .attention {
    border: 1px solid #f682a6;
    border-radius: 10px;
    font-size: 20px;
    color: #f682a6;
    padding: 0.5em 1em;
    margin-bottom: 0.5em;
    display: inline-block;
  }

  .attention a {
    color: #f682a6;
    border-bottom: 1px solid #f682a6;
    text-decoration: none;
  }

  /* contentsNav
---------------------------------------- */
  .contentsNav {
    margin: -20px 0 60px;
    white-space: nowrap;
  }

  .contentsNav li {
    display: inline-block;
    margin-right: 21px;
  }

  /* pageNav
---------------------------------------- */
  .pageNav {
    margin-bottom: 60px;
    text-align: center;
    line-height: 20px;
  }

  section + .pageNav {
    margin-top: -20px;
  }

  .pageNav:last-child {
    margin-bottom: 0;
  }

  #searchResult .pageNav {
    margin-top: 60px;
  }

  .pageNav > a,
  .pageNav ul,
  .pageNav li {
    display: inline-block;
    vertical-align: middle;
  }

  .pageNav ul {
    margin: 0 7px;
  }

  .pageNav li {
    margin: 0 3px 5px;
  }

  .pageNav li a,
  .pageNav li span {
    display: block;
    width: 3em;
    border: solid 1px;
    border-radius: 4px;
    background: url(../img/common/page_nav_bg.png) 50% 50% no-repeat;
  }

  .pageNav li span {
    /* opacity: .3; */
  }

  .pageNav li a:hover {
    text-decoration: none;
  }

  /* flex, listup
---------------------------------------- */
  .flex,
  .listup {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flexbox;
    display: -moz-flexbox;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: -moz-flex;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 750px;
  }

  .flex > li,
  .listup > li {
    width: 285px;
    margin: 30px 30px 0 0;
    padding: 20px 30px;
  }

  .flex > li:nth-child(1),
  .flex > li:nth-child(2),
  .listup > li:nth-child(1),
  .listup > li:nth-child(2) {
    margin-top: 0;
  }

  .flex > .long {
    width: 660px;
  }

  .flex > .long + li {
    margin-top: 30px;
  }

  /* listup */
  p + .listup {
    margin-top: 40px;
  }

  .listup > li {
    width: 333px;
    padding: 30px 5px;
    border: solid 1px #f2f2f2;
    background: #fafafa;
    text-align: center;
  }

  .listup > .break {
    margin-right: 300px;
  }

  .listup > .break + li {
    margin-top: 30px;
  }

  .listup .img {
    height: 205px;
  }

  .listup .img img {
    max-height: 100%;
  }

  .listup.imgL .img,
  .listup .imgL .img {
    height: 273px;
  }

  .listup p {
    margin: 15px 0;
    font-size: 18px;
    line-height: 1.5;
  }

  .listup p:first-child {
    margin-top: 0;
  }

  .listup .textM {
    padding: 0 15px;
    font-size: 14px;
    line-height: 1.7;
  }

  .listup .note {
    font-size: 12px;
    line-height: 1.7;
  }

  .listup a {
    display: block;
    width: 145px;
    height: 29px;
    margin: auto;
    background: 50% 50% no-repeat;
    text-indent: -20000px;
  }

  .listup a.pcHide {
    display: none;
  }

  .listup .img + a {
    margin-top: 20px;
  }

  .listup .textLink {
    display: inline;
    width: auto;
    height: auto;
    background: none;
    text-indent: 0;
  }

  /* Image Layouts
---------------------------------------- */
  figcaption {
    margin: 5px auto 0;
  }

  .imgList {
    margin-bottom: 40px;
    text-align: center;
    @include sp {
      margin: 0 auto 20px;
      width: 100%;
    }
  }

  p + .imgList {
    margin-top: 35px;
    @include sp {
      margin-top: 20px;
    }
  }

  .imgList + p {
    margin-top: -5px;
  }

  .imgList + .imgList {
    margin-top: -20px;
    @include sp {
      margin-top: 0;
    }
  }

  .imgList:last-child {
    margin-bottom: 0;
    @include sp {
      margin-bottom: 0;
      margin-left: 0;
    }
  }

  .imgList img,
  .imgList li {
    display: inline-block;
    max-width: 530px;
    margin: 0 5px;
    vertical-align: top;
    @include sp {
      margin: 0 0 5%;
      max-width: 100%;
    }
  }

  .imgList figcaption ul {
    margin-left: -5px;
    white-space: nowrap;
  }

  .imgList figcaption li {
    white-space: normal;
    text-align: left;
  }

  .imgList.col2 img,
  .imgList.col2 li {
    width: 260px;
    @include sp {
      width: 48.5%;
    }
  }

  .imgList.col2 figcaption {
    width: 530px;
    @include sp {
      width: auto;
    }
  }

  .imgList.col3 img,
  .imgList.col3 li {
    width: 200px;
    @include sp {
      width: 32%;
    }
  }
  .imgList img + img,
  .imgList img + a,
  .imgList a + img,
  .imgList a + a,
  .imgList li + li {
    @include sp {
      margin-left: 2%;
    }
  }
  .imgList.col3 figcaption {
    width: 620px;
    @include sp {
      width: auto;
    }
  }

  .imgList.fullSize img {
    width: auto;
    max-width: 100%;
  }

  .imgList figcaption.fullSize {
    width: auto;
    text-align: left;
  }

  /* imgBox */
  .imgBox {
    margin-bottom: 40px;
  }

  .imgBox:last-child {
    margin-bottom: 0;
  }

  .imgBox > div {
    float: left;
    width: 470px;
  }

  .imgBox > figure {
    float: right;
    width: 220px;
  }

  .imgBox > figure img {
    margin-top: 20px;
  }

  .imgBox > figure figcaption + img {
    margin-top: 15px;
  }

  .imgBox > figure img:first-child {
    margin-top: 0;
  }

  /* movie
---------------------------------------- */
  .movie {
    position: relative;
    padding-top: 56.25%;
  }

  .movie > * {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  /* outlineList
---------------------------------------- */
  .outlineList {
    margin-bottom: 35px;
  }

  .outlineList:last-child {
    margin-bottom: 0;
  }

  .outlineList + p {
    margin-top: -5px;
  }

  .outlineList dt {
    float: left;
    clear: left;
    width: 130px;
    text-align: right;
    @include sp {
      float: none;
      width: auto;
      text-align: left;
    }
  }

  .outlineList dt:after {
    content: "：";
  }

  .outlineList dd {
    float: left;
    width: 520px;
    @include sp {
      float: none;
      width: auto;
    }
  }

  /* slider
---------------------------------------- */
  .sliderWrap {
    overflow: hidden;
    position: relative;
    margin: auto;
    padding-bottom: 52px;
  }

  .slider {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .slided {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .slider .active {
    z-index: 10;
  }

  .slider .empty {
    display: none;
  }

  .sliderPrev,
  .sliderNext {
    position: absolute;
    top: 142px;
    z-index: 501;
    width: 40px;
    height: 40px;
    margin: 0 10px;
    background: 0 0 no-repeat;
  }

  .sliderPrev {
    right: 100%;
    background-image: url(../img/common/slider_prev.png);
  }

  .sliderNext {
    left: 100%;
    background-image: url(../img/common/slider_next.png);
  }

  .sliderNav {
    position: absolute;
    left: 0;
    bottom: 26px;
    z-index: 500;
    width: 100%;
    height: 6px;
    text-align: center;
  }

  .sliderNav li {
    display: inline-block;
    width: 31px;
    height: 6px;
    margin: 0 2px;
  }

  .sliderNav a {
    display: block;
    width: 100%;
    height: 100%;
    background: #b1b1b1;
    text-indent: -20000px;
  }

  .sliderNav .active a {
    background: #ff7800;
    cursor: default;
  }

  .sliderNav .active a:hover {
    opacity: 1;
  }

  /* linkSectionTop
---------------------------------------- */
  #linkSectionTop {
    margin-top: 60px;
    text-align: center;
    font-size: 18px;
    line-height: 1;
  }

  /* comingsoon
---------------------------------------- */
  #comingsoon {
    display: block;
    margin: 40px auto 0;
  }

  /* adide
---------------------------------------- */
  aside {
    float: left;
    width: 220px;
    background: #fff;
  }

  aside > p {
    font-size: 18px;
    line-height: 1;
    padding-bottom: 15px;
    border-bottom: solid 1px #dadada;
  }

  aside a {
    display: block;
    padding: 15px 5px;
    border-bottom: solid 1px #dadada;
    text-decoration: none;
    color: #313131;
  }

  aside a:hover,
  aside .on a {
    opacity: 1;
    background: #f7f7f7;
    text-decoration: none !important;
  }

  aside.fixed {
    position: fixed;
    top: 0;
    padding-top: 15px;
  }

  /* ----------------------------------------
	uncategorized
---------------------------------------- */
  .uncategorized nav li:after {
    display: none;
  }

  .uncategorized article {
    float: none;
    width: 100%;
  }

  .uncategorized h2 {
    min-height: 31px;
  }

  /* ----------------------------------------
	footer
---------------------------------------- */
  footer a {
    text-decoration: none;
  }

  #btnPagetop {
    display: none;
    position: fixed;
    right: 50px;
    bottom: 0;
    z-index: 10;
    width: 52px;
    height: 0;
    margin-bottom: 0;
    padding-top: 100px;
    background: url(../img/sp/common/btn_pagetop.png) no-repeat;
    background-size: contain;
    text-indent: -20000px;
    transition: 0s;
  }

  #footerNav {
    position: relative;
    padding: 40px 0;
    background: #ff7800;
  }

  #footerNav > ul {
    width: 970px;
    margin: auto;
  }

  #footerNav > ul > li {
    float: left;
    width: 220px;
    margin-right: 30px;
  }

  #footerNav > ul > li:last-child {
    margin: 0;
  }

  #footerNav p,
  #footerNav a {
    color: #fff;
  }

  #footerNav p {
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 1;
  }

  /*#footerNav>ul>li:last-child ul:last-child {
	position: absolute;
	bottom: 40px;
}*/
  #footerNav li li {
    margin-bottom: 5px;
  }

  #footerNav li li:last-child {
    margin: 0;
  }

  #footerNav a {
    display: block;
    position: relative;
    padding-left: 1em;
    text-decoration: none;
  }

  #footerNav a:before {
    content: "- ";
    position: absolute;
    left: 0;
    top: 0;
  }

  #footerNav .project {
    margin-top: 0px;
  }

  /* #footerNav .employee {
    margin-top: 55px;
} */

  #footerNav .employee {
    margin-top: 0px;
  }

  #footerNav .company {
    margin-top: 0px;
  }

  /* #footerNav .library {
    margin-top: 25px;
} */

  #footerNav .library {
    margin-top: 54px;
  }

  #footerNav .supervisor {
    margin-top: 0px;
  }

  /* #footerNav .seminar {
    margin-top: 25px;
} */

  /* #footerNav .seminar {
    margin-top: 0px;
} */

  #footerNav .seminar {
    margin-top: 106px;
  }

  /* #footerNav .event {
    margin-top: 25px;
} */

  /* #footerNav .event {
    margin-top: 75px;
} */

  #footerNav .event {
    margin-top: 25px;
  }

  #footerNav .sitemap {
    margin-top: 30px;
  }

  #footerNav .sitemap2 {
    width: 970px;
    margin: auto;
    margin-top: 20px;
  }

  #footerNav .sitemap2 ul {
    float: right;
  }

  #footerNav .sitemap2 li {
    display: inline-block;
    margin-left: 20px;
  }

  /* footerBnr
---------------------------------------- */
  #footerBnr {
    padding: 40px 0;
    background: #f2f1f1;
  }

  #footerBnrSliderWrap {
    width: 970px;
    height: 100px;
    padding-bottom: 6px;
  }

  #footerBnrSlider li {
    width: 194px;
    text-align: center;
  }

  #footerBnrSlider li img {
    max-width: 150px;
    max-height: 100%;
    vertical-align: top;
  }

  #footerBnr .sliderNav {
    bottom: 0;
  }

  /* footerBottom
---------------------------------------- */
  #footerBottom {
    width: 970px;
    margin: auto;
    min-height: 57px;
    padding-top: 23px;
    padding-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }

  footer .subNav {
    display: inline-block;
    margin-bottom: 10px;
  }

  #footerBottom p {
    font-size: 10px;
    line-height: 1;
  }

  #footerBottom .mhlwLogo {
    position: relative;
    width: 420px;
    height: 57px;
    background: url(../img/common/mhlw_logo.png) no-repeat;
  }

  #footerBottom .mhlwLogo span {
    position: absolute;
    top: 50%;
    right: -40px;
    transform: translateY(-50%);
    color: #000;
    font-size: 11px;
    line-height: 1.6;
  }

  /* ----------------------------------------
	parts
---------------------------------------- */
  span.new {
    padding: 3px 6px;
    font-weight: bold;
    color: #fff;
    background-color: #ff0000;
    border-radius: 3px;
    margin: -3px 0 0 3px;
    font-size: 0.8em;
  }

  .clearfix:after {
    content: ".";
    display: block;
    visibility: hidden;
    clear: both;
    height: 0;
    font-size: 0;
    line-height: 0;
  }
  .c-mb-10 {
    margin-bottom: 10px;
  }

  /* ++++++++++++++++++++++++++++++++++++++++

	/project/activity/

++++++++++++++++++++++++++++++++++++++++ */
  .pageNav {
    /* height: 34px; */
    margin: -20px 0 40px;
    text-align: left;
    display: flex;
    flex-wrap: wrap;
  }

  .pageNav li {
    width: 118px;
    margin: 1px 1px;
  }

  .pageNav li a,
  .pageNav li span {
    opacity: 1;
    width: auto;
    border: 0;
    border-radius: 0;
    background: #ededed;
    text-align: center;
    color: #009bc7;
    font-size: 16px;
    line-height: 34px;
  }

  .pageNav li a:hover,
  .pageNav li span {
    color: #fff;
    background: #ff7800;
  }

  .pageNavSub {
    margin: 30px 0;
    text-align: left;
    border: solid 1px #ff7800;
  }

  .pageNavSub li {
    position: relative;
    /* width: 118px; */
    margin: 0;
  }

  .pageNavSub li a {
    opacity: 1;
    display: block;
    width: auto;
    border-bottom: 1px solid #ff7800;
    border-radius: 0;
    background: #fff;
    color: #ff7800;
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
    padding: 2px 10px 2px 122px;
  }

  .pageNavSub__link--2021 {
    padding: 2px 10px 2px 130px !important;
  }

  .pageNavSub__link--2022 {
    padding: 2px 10px 2px 140px !important;
  }

  .pageNavSub__link--2022 span {
    width: 9.3em;
  }

  /* 20240130 start */
  .pageNavSub__link--2023 {
    padding: 2px 10px 2px 180px !important;
  }

  .pageNavSub__link--2023 span {
    width: 12.3em;
  }
  /* 20240130 end */

  .pageNavSub li:last-child a {
    border-bottom: none;
  }

  .pageNavSub li a:hover {
    color: #ff7800;
  }

  .pageNavSub li.short span {
    padding-right: 10px;
  }

  .pageNavSub li span {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #ff7800;
    border-bottom: solid 1px #ffbaba;
    box-sizing: border-box;
    color: #fff;
    padding: 3px 21px 3px 6px;
    margin-left: 0;
    margin-right: 5px;
  }

  .pageNavSub__dateTxt--short {
    padding: 3px 43px 3px 6px !important;
  }

  section section {
    margin: 40px 0 0;
    padding: 0;
    border: 0;
    @include sp {
      margin-top: 20px;
    }
  }

  h3 {
    margin-bottom: 15px;
    padding: 0;
    border: 0;
    color: #ff7800;
    font-size: 20px;
    @include sp {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }

  h3:before {
    display: none;
  }

  h4,
  h4.subtitle {
    margin-bottom: 15px;
  }

  h5 {
    margin-bottom: 10px;
    font-weight: normal;
    font-size: 20px;
    line-height: 1.4;
  }

  h5 small {
    font-size: 14px;
  }

  section p {
    margin-bottom: 10px;
  }

  section small {
    font-size: 70%;
  }

  h6 {
    margin-top: 10px;
    font-size: 17px;
    font-weight: normal;
  }

  section .comments dt {
    margin: 0 auto 10px;
    font-weight: bold;
    border-bottom: 1px solid #7d7d7d;
  }

  section .comments dd {
    margin: 0 auto 30px;
  }

  section .comments.img_commments dd:after {
    content: "";
    display: block;
    clear: both;
  }

  section .comments.img_commments dd > div {
    float: left;
    width: 70%;
  }

  section .comments li {
    padding-left: 1em;
    text-indent: -1em;
  }

  section .comments.img_commments dd > figure {
    float: left;
    width: 30%;
    box-sizing: border-box;
    padding-left: 2%;
    text-align: center;
    font-size: 12px;
  }

  section .comments dd span {
    color: #cb951b;
    display: block;
  }

  .imgList.medium img {
    max-width: 460px;
    @include tb {
      max-width: calcTb(460);
    }
  }

  .imgList.large img {
    max-width: 640px;
    @include tb {
      max-width: calcTb(640);
    }
  }

  /* ----------------------------------------
	imgBox
---------------------------------------- */
  .imgBox > div {
    float: right;
    width: 375px;
    @include tb {
      width: calcTb(375);
    }
    @include sp {
      float: none;
      width: auto;
    }
  }

  .imgBox > figure {
    float: left;
    width: 260px;
    @include tb {
      width: calcTb(260);
    }
    @include sp {
      float: none;
      width: auto;
    }
  }

  .imgBox > figure img {
    width: 100%;
    margin-top: 10px;
  }

  .imgBox figcaption {
    text-align: center;
  }

  /* ----------------------------------------
	imgBox2
---------------------------------------- */
  .imgBox2 {
    margin-bottom: 30px;
  }

  .imgBox2 > figure {
    margin: 20px auto;
    text-align: center;
  }

  /* ----------------------------------------
	last-child
---------------------------------------- */
  section p:last-child,
  .imgList:last-child,
  .imgBox:last-child,
  .imgBox img:last-child,
  .imgBox img + figcaption:last-child {
    margin-bottom: 0;
  }

  /* ----------------------------------------
	award
---------------------------------------- */
  .award table {
    float: left;
    width: 450px;
    border: 1px solid;
    font-weight: 600;
    @include sp {
      width: 100%;
    }
  }

  .award table tr {
    border: 1px solid;
    text-align: center;
  }

  .award table tr td {
    border: 1px solid;
    padding: 5px 10px;
  }

  .award table tr td span {
    font-weight: normal;
  }

  .award table tr td.title {
    padding: 5px 10px;
    background: #aaa;
  }

  .award img {
    float: right;
    width: 155px;
    @include sp {
      float: none;
      margin-top: 10px;
    }
  }

  .report_btn {
    border: 1px solid;
    padding: 4px 35px;
    border-radius: 5px;
    color: #009bc7;
    line-height: 30px;
    @include sp {
      display: block;
      text-align: center;
      line-height: 1.5;
    }
  }

  .channel_btn {
    border: 1px solid;
    padding: 4px 35px;
    border-radius: 5px;
    color: #ff7800;
    line-height: 30px;
    @include sp {
      display: block;
      text-align: center;
      line-height: 1.5;
    }
  }

  #ikumen_2020_campaign .bnr {
    display: block;
    width: 350px;
    @include sp {
      width: 100%;
    }
  }

  #online-seminar2021 .bnr {
    display: block;
    width: 350px;
    @include sp {
      width: 100%;
    }
  }

  #seminar2021 .bnr {
    display: block;
    width: 350px;
    @include sp {
      width: 100%;
    }
  }

  #ikumen_2020_campaign .bnr > img {
    width: 100%;
  }

  .bnr__img--seminar2021 {
    display: block;
    max-width: 650px;
    margin-top: 25px;
    @include sp {
      max-width: 100%;
    }
  }

  .univ {
    position: relative;
  }

  .univ > img {
    position: absolute;
    right: 0;
    top: 0;
    width: 140px;
    @include sp {
      position: relative;
    }
  }

  #demaekeio b {
    font-weight: bold;
  }

  .underline {
    text-decoration: underline;
  }

  .red {
    color: #ff7800;
  }

  strong {
    color: #ff7800;
    font-weight: bold;
  }

  strong.fsize18 {
    font-size: 18px;
  }

  .dl_list dt {
    position: absolute;
  }

  .dl_list dd {
    padding-bottom: 0.8em;
  }

  .dl_list.dt6 dt {
    width: 6em;
  }

  .dl_list.dt8 dt {
    width: 8em;
  }

  .dl_list.dt10 dt {
    width: 10em;
  }

  .dl_list.dt12 dt {
    width: 12em;
  }

  .dl_list.dt6 dd {
    padding-left: 7.5em;
  }

  .dl_list.dt8 dd {
    padding-left: 9.5em;
  }

  .dl_list.dt10 dd {
    padding-left: 11.5em;
  }

  .dl_list.dt12 dd {
    padding-left: 13.5em;
  }

  .talk {
    border-bottom: dotted 1px #000;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  .talk--noBorder dl {
    margin-bottom: 20px;
  }

  .talk--noBorder dt {
    position: absolute;
    width: 5em;
    text-align: right;
    font-weight: bold;
  }

  .talk--noBorder dd {
    margin-left: 6em;
  }

  .talk dl {
    margin-bottom: 20px;
  }

  .talk dt {
    position: absolute;
    width: 5em;
    text-align: right;
    font-weight: bold;
  }

  .talk dd {
    margin-left: 6em;
  }

  .indent_list li {
    text-indent: -1em;
    padding-left: 1em;
  }

  .font_large {
    font-size: 18px;
  }

  b.strong {
    display: block;
    font-size: 17px;
    font-weight: normal;
    margin-bottom: 5px;
  }

  .c-font__red {
    color: #ff7800;
  }

  .c-font__gold {
    color: #c1ab05;
  }

  .c-font__bold {
    font-weight: bold;
  }

  .c-border__bottom {
    display: inline !important;
    border-bottom: 3px solid;
    line-height: 2;
  }

  .c-border-bottom__dotted {
    border-bottom: dotted 1px #000;
    margin-bottom: 30px;
    padding-bottom: 10px;
  }

  .p-activity-comment__img {
    width: 200px;
    height: auto;
  }

  .p-activity-hukidashi-list {
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
  }

  .p-activity-hukidashi-list-item {
    width: 30%;
  }

  .p-activity-hukidashi-list-item-modal {
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0.8);
  }

  .p-activity-hukidashi-list-item-modal__bg {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .p-activity-hukidashi-list-item-modal-content {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
  }

  .p-activity-hukidashi-list-item-modal-content--1 {
    background-image: url(/project/activity/2020/img/hukidashi1_bg.png);
    padding: 0px 300px;
    width: 35%;
    height: 80vh;
  }

  .p-activity-hukidashi-list-item-modal-content--2 {
    background-image: url(/project/activity/2020/img/hukidashi2_bg.png);
    padding: 0px 300px;
    width: 44%;
    height: 80vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3 {
    background-image: url(/project/activity/2020/img/hukidashi3_big_bg.png);
    padding: 0px 300px;
    width: 40%;
    height: 80vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-1 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0%;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    width: 38%;
    height: 45vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-2 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 55vh 0 0;
    width: 38%;
    height: 45vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-3 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 110vh 0 0;
    width: 30%;
    height: 30vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-4 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 150vh 0 0;
    width: 30%;
    height: 33vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-5 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 195vh 0 0;
    width: 30%;
    height: 33vh;
  }

  .p-activity-hukidashi-list-item-modal-content--3-6 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 240vh 0 0;
    width: 30%;
    height: 45vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-1 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0%;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    width: 38%;
    height: 45vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-2 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 55vh 0 0;
    width: 38%;
    height: 45vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-3 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 110vh 0 0;
    width: 30%;
    height: 30vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-4 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 150vh 0 0;
    width: 40%;
    height: 50vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-5 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 210vh 0 0;
    width: 35%;
    height: 40vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-6 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 260vh 0 0;
    width: 35%;
    height: 40vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-7 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 310vh 0 0;
    width: 35%;
    height: 35vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-8 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 355vh 0 0;
    width: 40%;
    height: 60vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-9 {
    background-image: url(/project/activity/2020/img/hukidashi3_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 425vh 0 0;
    width: 20%;
    height: 25vh;
  }

  .p-activity-hukidashi-list-item-modal-content--6-10 {
    background-image: url(/project/activity/2020/img/hukidashi4_bg.png);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, 0%);
    display: flex;
    align-items: center;
    padding: 0px 300px;
    margin: 460vh 0 0;
    width: 30%;
    height: 40vh;
  }

  /* 20240130 start */
  /* ++++++++++++++++++++++++++++++++++++++++

	/project/activity/2023/

++++++++++++++++++++++++++++++++++++++++ */

  .survey_overview li {
    text-indent: 0em;
    padding-left: 0em;
    margin-bottom: 0em;
  }

  .survey_result li {
    text-indent: -1.2em;
    padding-left: 1.2em;
    margin-bottom: 1em;
  }

  .survey_result .emphasization {
    color: #ff7800;
  }
  /* 20240130 end */
}
